import {formatMoney} from "../utilities/Currency";
import {updateBisButtonDisplay} from "./KlaviyoBIS";

class ProductCardVariantSelector {

  updateCartButton = (button, soldOut) => {
    if (soldOut) {
      button.disabled = true;
      button.classList.add('disabled');
      button.innerText = theme.strings.soldOut;
    } else {
      button.disabled = false;
      button.classList.remove('disabled');
      button.innerText = theme.strings.addToCart;
    }
  };

  constructor() {
    this.variantSelectors = document.querySelectorAll('.product-form__variant-selector input[type="radio"]');
    this.productCards = document.querySelectorAll('.product-card');
    this.emitVariantJson();
    this.registerVariantChangeHandler();
    this.updateCartButtonPrice();
  }

  emitVariantJson() {
    const handlerFunction = (evt) => {
      const variantJson = JSON.parse(evt.target.dataset.variant);
      const variantChangeEvent = new CustomEvent("variantChange", {
        detail: {variant: variantJson},
      });

      document.dispatchEvent(variantChangeEvent);
    };

    this.variantSelectors.forEach((selector) => {
      selector.addEventListener('change', (evt) => handlerFunction(evt));
    });
  }

  registerVariantChangeHandler() {
    this.productCards.forEach((card) => {
      const klaviyoContainer = card.querySelector('.klaviyo-product-container');
      const buyProductBtn = card.querySelector('.j-buy-product .button-label');

      card.addEventListener('change', (evt) => {
        const radio = evt.target;

        // Check if the event was triggered by a radio input matching the criteria
        if (radio.matches('input[type="radio"][id^="Variant-"]')) {
          const variant = JSON.parse(radio.dataset.variant);

          updateBisButtonDisplay(klaviyoContainer, variant.available ? 'add' : 'remove');
          this.updateCartButton(buyProductBtn, !variant.available);
        }
      });
    });

  }

  updateCartButtonPrice() {
    document.addEventListener('variantChange', (evt) => {
      //console.log(evt.target.activeElement.closest('form').querySelector('.product-form__add-button'));
      if (!evt.target.activeElement.closest('form').querySelector('.product-form__add-button')) {
        return;
      }
      const variant = evt.detail.variant;
      evt.target.activeElement.closest('form').querySelector('.product-form__add-button span').innerText = formatMoney((variant.selling_plan_allocations.length ? variant.selling_plan_allocations[0].price : variant.price), theme.settings.moneyFormat, true);
    });
  }
}

export default ProductCardVariantSelector;
